import API from "Services";
import Cookies from "js-cookie";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  GET_ALL_PROJECT_LEADS,
  EDIT_PROJECT_LEADS,
  ADD_PROJECT_LEADS,
  LEAD_CLIENT,
  DELETE_PROJECT_LEADS,
  GET_SINGLE_PROJECT_LEADS,
  FETCH_CONSULTANTS_FOR_PROJECT_LEAD,
  FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED,
  FETCH_CONSULTANTS_FOR_PROJECT_LEAD_TYPESENSE,
  FETCH_CONSULTANTS_FOR_PROJECT_LEAD_SOVREN,
  BROADCAST_REQUIREMENTS_FOR_PROJECT_LEAD,
  ADD_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD,
  REMOVE_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD,
  SEND_LEAD_VALIDATION_EMAIL,
  GENERATE_LEAD_LOGIN,
  SAVE_CONSULTANT_RESPONSE,
  GET_ALL_CONSULTANT_RESPONSE,
  UPDATE_CONSULTANT_RESPONSE,
  GET_SHORTLISTED_CONSULTANTS,
  SEND_SHORTLISTED_CONSULTANTS_EMAIL,
  UPDATE_CONSULTANT_RESPONSE_WITH_TOKEN,
  INDEX_ENTITY_IN_SOVREN,
  SEND_APPLICATION_UPDATE_EMAIL,
  GET_ADMIN_SHORTLIST_URL,
  GET_ALL_MARVIN_LEADS,
  GET_MARGVIN_LEAD_BY_ID,
  EDIT_MARVIN_LEAD,
  APPROVE_MARVIN_LEAD,
  SOFT_DELETE_TEMP_LEADS,
  SOFT_DELETE_CONSULTANT_REQUIREMENTS
} from "Utils/ApiConstants";

const fetchProjectLead = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}`;
    const response = await API.get(GET_ALL_PROJECT_LEADS + filters);
    // const response = await API.get(
    //   `${GET_ALL_PROJECT_LEADS}?from=${data.from}&offset=${data.offset}&sort_order=DESC&user_type=${data.user_type}&user_id=${data.user_id}`
    // );
    if (response && response.data) return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

const fetchMarvinLeads = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}`;
    let response = await API.get(GET_ALL_MARVIN_LEADS + filters);
    if (response && response.data) return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
  }
}

export const fetchProjectLeadExport = async (data) => {
  
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}&export=true`;
    const response = await API.get(GET_ALL_PROJECT_LEADS + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }


};

export const fetchLeadByIdService = async (data) => {
  try {
    let filters = `?id=${data}`;
    const response = await API.get(GET_SINGLE_PROJECT_LEADS + filters);
    // const response = await API.get(
    //   `${GET_ALL_PROJECT_LEADS}?from=${data.from}&offset=${data.offset}&sort_order=DESC&user_type=${data.user_type}&user_id=${data.user_id}`
    // );
    if (response && response.data) return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const removeConsultantRequirement = async (id) => {
  try {
    const response = await API.post(SOFT_DELETE_CONSULTANT_REQUIREMENTS, {requirement_id: id});
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
}

export const fetchUnapprovedLeadById = async (data) => {
  try {
    let filters = `?id=${data}`;
    const response = await API.get(GET_MARGVIN_LEAD_BY_ID + filters);
    // const response = await API.get(
    //   `${GET_ALL_PROJECT_LEADS}?from=${data.from}&offset=${data.offset}&sort_order=DESC&user_type=${data.user_type}&user_id=${data.user_id}`
    // );
    if (response && response.data) return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}

export const approveMarvinLead = async (data) => {
  try {

    const response = await API.post(APPROVE_MARVIN_LEAD, data);
    if (response && response.data) return response.data;
    
  } catch (error) {
     if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
  }
}

export const useApproveMarvinLeadService = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(approveMarvinLead, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-marvin-leads");
      return onSuccess();
    }
  });
}


export const useRemoveConsultantRequirementService = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(removeConsultantRequirement, {
    onSuccess: () => {
      // queryClient.invalidateQueries("all-marvin-leads");
      return onSuccess();
    }
  });
}

export const useAllProjectLeadService = (onSuccess, onError, data) => {
  return useQuery(["all-project-lead", data], () => fetchProjectLead(data), {
    onSuccess,
    onError,
  });
};

export const useAllMarvinLeadsService = (onSuccess, onError, data) => {
  return useQuery(["all-marvin-leads", data], () => fetchMarvinLeads(data), {
    onSuccess,
    onError,
  });
}

export const saveLeadClientService = async (data) => {
  try {
    const response = await API.post(LEAD_CLIENT, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

const addProjectLead = async (data) => {
  try {
    const response = await API.post(ADD_PROJECT_LEADS, data);
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useAddProjectLeadService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addProjectLead, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-project-lead");
      return onSuccesss();
    },
  });
};

const editProjectLead = async (data) => {
  try {
    const response = await API.post(EDIT_PROJECT_LEADS, data);
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const editUnapproveLead = async (data) => {
  try {
    const response = await API.post(EDIT_MARVIN_LEAD, data);
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/"
    }
  }
}

export const useEditUnapproveLeadService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(editUnapproveLead, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-marvin-leads");
      return onSuccesss();
    },
  });

}

export const useSoftDeleteTempLeadsService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(softDeleteTempLeads, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-marvin-leads");
      return onSuccesss();
    },
  });

}

export const useEditProjectLeadService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(editProjectLead, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-project-lead");
      return onSuccesss();
    },
  });
};

export const useDeleteProjectLeadsService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteProjectLeads, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-project-lead");
    },
  });
};
const deleteProjectLeads = async (data) => {
  try {
    const response = await API.get(`${DELETE_PROJECT_LEADS}?id=${data}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchConsultantsForProjectLead = async (requirements, added_consultants, removed_consultants, lead_id) => {
  try {
    // let query_str = `?requirements=${JSON.stringify(requirements)}`;
    // if(added_consultants){
    //   query_str += `&added_consultants=${JSON.stringify(added_consultants)}`;
    // }
    // if (removed_consultants) {
    //   query_str += `&removed_consultants=${JSON.stringify(removed_consultants)}`;
    // }
    // if(lead_id){
    //   query_str += `&lead_id=${lead_id}`;
    // }
    const response = await API.post(FETCH_CONSULTANTS_FOR_PROJECT_LEAD, { requirements, added_consultants, removed_consultants, lead_id });
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchConsultantsForProjectLeadIndexed = async (requirements, added_consultants, removed_consultants, lead_id) => {
  try {
    // let query_str = `?requirements=${JSON.stringify(requirements)}`;
    // if(added_consultants){
    //   query_str += `&added_consultants=${JSON.stringify(added_consultants)}`;
    // }
    // if (removed_consultants) {
    //   query_str += `&removed_consultants=${JSON.stringify(removed_consultants)}`;
    // }
    // const response = await API.get(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED + query_str);
    const response = await API.post(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED, { requirements, added_consultants, removed_consultants, lead_id });
    // if (response && response.data) return response.data.data;
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchConsultantsForProjectLeadTypesense = async (requirements, added_consultants, removed_consultants, lead_id) => {
  try {
    // let query_str = `?requirements=${JSON.stringify(requirements)}`;
    // if(added_consultants){
    //   query_str += `&added_consultants=${JSON.stringify(added_consultants)}`;
    // }
    // if (removed_consultants) {
    //   query_str += `&removed_consultants=${JSON.stringify(removed_consultants)}`;
    // }
    // const response = await API.get(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED + query_str);

    //get the conversation_id
    let conversation_id = Cookies.get("typesense-recommendation-conversation-id");
    const response = await API.post(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_TYPESENSE, { requirements, added_consultants, removed_consultants, lead_id, conversation_id });
    // if (response && response.data) return response.data.data;
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchConsultantsForProjectLeadSovren = async (requirements, added_consultants, removed_consultants, lead_id, preferred_weights) => {
  try {
    // let query_str = `?requirements=${JSON.stringify(requirements)}`;
    // if(added_consultants){
    //   query_str += `&added_consultants=${JSON.stringify(added_consultants)}`;
    // }
    // if (removed_consultants) {
    //   query_str += `&removed_consultants=${JSON.stringify(removed_consultants)}`;
    // }
    // const response = await API.get(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED + query_str);
    const response = await API.post(FETCH_CONSULTANTS_FOR_PROJECT_LEAD_SOVREN, { requirements, added_consultants, removed_consultants, lead_id, preferred_weights });
    // if (response && response.data) return response.data.data;
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const broadcastRequirementsForProjectLead = async (data) => {
  try {
    const response = await API.post(BROADCAST_REQUIREMENTS_FOR_PROJECT_LEAD, data);
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addConsultantSuggestionForProjectLead = async (lead_id, consultant_id, requirement_id) => {
  try {
    const response = await API.post(ADD_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD, { lead_id, consultant_id, requirement_id });
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const removeConsultantSuggestionForProjectLead = async (lead_id, consultant_id, requirement_id) => {
  try {
    const response = await API.post(REMOVE_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD, { lead_id, consultant_id, requirement_id });
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const sendLeadValidationEmail = async (lead_id) => {
  try {
    const response = await API.post(SEND_LEAD_VALIDATION_EMAIL, { lead_id });
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const generateLeadLogin = async (token) => {
  try {
    const response = await API.post(GENERATE_LEAD_LOGIN, { token });
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const saveConsultantResponse = async (data) => {
  try {
    const response = await API.post(SAVE_CONSULTANT_RESPONSE, data);
    if (response && response.data && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}

export const getConsultantResponse = async (values) => {
  try {
    
    const response = await API.get(GET_ALL_CONSULTANT_RESPONSE , {
      params: {
        requirement_id: values?.requirement_id
      }
    });
    if (response && response.data && response.data) return response.data.data;

  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const updateConsultantResponse = async (values) => {
  
  try {
     return await API.put(UPDATE_CONSULTANT_RESPONSE, values);
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}


export const getShortlistedConsultants = async (values) => {
  try {
     const response = await API.get(GET_SHORTLISTED_CONSULTANTS, {
      params: {
        id: values
      }
     });

     if (response.data) return response.data;
  } catch (error) {
    
  }
}

export const sendShortlistedConsultantEmail = async (data) => {
  try {
    const response = await API.post(SEND_SHORTLISTED_CONSULTANTS_EMAIL, data);

    return response;
  } catch (error) {
    return new Error(error)
  }
}

export const updateConsultantResponseWithToken = async (data) => {
  
  try {
    const response = await API.put(UPDATE_CONSULTANT_RESPONSE_WITH_TOKEN, data);

    return response;

  } catch (error) {
    return new Error(error);
  }
}

export const indexEntityInSovren = async (data) => {

  try {
    const response = await API.post(INDEX_ENTITY_IN_SOVREN, data);

    return response.data;

  } catch (error) {
    return new Error(error);
  }
}


export const sendApplicationStatusEmail = async (data) => {
  try {
    await API.post(SEND_APPLICATION_UPDATE_EMAIL, data);
  } catch (error) {
    return new Error(error)
  }
}

export const sendNewMessageEmail = async (data) => {
  
}

export const getAdminShortlistUrl = async (data) => {
  try {
    const response = await API.get(`${GET_ADMIN_SHORTLIST_URL}?requirement_id=${data.requirement_id}&lead_id=${data.lead_id}`);
    return response.data;
  } catch (error) {
    return new Error(error)
  }
}


export const softDeleteTempLeads = async (id) => {
  try {
    let queryParams = `?lead_id=${id}`;
    const response = await API.post(`${SOFT_DELETE_TEMP_LEADS}${queryParams}`);
    console.log(response)

    if (response.status === 200) {
      return response.data;
    }


  } catch (error) {
    return new Error(error)
  }
}